<template>
  <base-layout-two :page-title="(transaction.type === 'charge') ? 'Payment' : 'Line Item'"
    :page-default-back-link="`/users/${$route.params.id}/folios/${$route.params.folio_id}`" end-button-text="Edit"
    :end-button-url="`/users/${$route.params.id}/folios/${$route.params.folio_id}/transactions/${$route.params.transaction_id}/edit`">

    <section class="ion-padding background-white">
      <h1>{{ (transaction.type === 'charge') ? 'Payment' : 'Line Item' }}</h1>
    </section>

    <div class="padding-8">
      <base-card title="Transaction Details">
        <base-field-display label-text="Description">
          <p>{{ transaction.description }}</p>
        </base-field-display>

        <base-field-display label-text="Amount (after refunds if applicable)">
          <p :class="transactionColor">
            ${{ amount.toLocaleString("en", { minimumFractionDigits: 2 }) }}
          </p>
        </base-field-display>

        <base-field-display label-text="Date">
          <p>
            {{ formatDate(transaction.date, "MM-dd-yyyy") }}
          </p>
        </base-field-display>

        <base-field-display label-text="Stripe Details" v-if="transaction.type === 'charge'">
          <p>ID: {{ transaction.stripe_charge_id }}</p>
          <p>Charge Amount:
            ${{ (transaction.stripe_charge_amount / 100).toLocaleString("en", { minimumFractionDigits: 2 }) }}
          </p>
          <p>Refund Amount:
            ${{ (transaction.stripe_refund_amount / 100).toLocaleString("en", { minimumFractionDigits: 2 }) }}
          </p>
          <p>
            Receipt URL: {{ transaction.stripe_charge_receipt_url }}
          </p>
        </base-field-display>

        <base-field-display label-text="Memo">
          <p>{{ transaction.memo }}</p>
        </base-field-display>
      </base-card>
    </div>
  </base-layout-two>
</template>

<script>
import { formatDate } from "@/util/helpers";

export default {
  computed: {
    /*
    * Calculates the amount based on if this is a charge and if there is a refund
    * If this is a line item, return the regular amount
    */
    amount() {
      if (this.transaction.type === 'charge') {
        if (this.transaction.stripe_refund_amount)
          return (this.transaction.stripe_charge_amount - this.transaction.stripe_refund_amount) / 100
        else
          return (this.transaction.stripe_charge_amount) / 100
      }

      return this.transaction.amount
    },

    transactionColor() {
      if (this.transaction.amount < 0) return "danger";
      else return "";
    },
  },

  data() {
    return {
      transaction: {
        amount: 0,
      },
      formatDate,
    };
  },

  ionViewWillEnter() {
    this.fetchTransaction();
  },

  methods: {
    /**
     * Fetch Transaction
     */
    async fetchTransaction() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/transactions/${this.$route.params.transaction_id}`)
        .then((response) => {
          this.transaction = response.data.transaction;
        })
        .catch((error) => { console.log(error) });
    },
  },
};
</script>